exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-backup-js": () => import("./../../../src/pages/index.backup.js" /* webpackChunkName: "component---src-pages-index-backup-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-big-honkin-ideas-js": () => import("./../../../src/pages/projects/big-honkin-ideas.js" /* webpackChunkName: "component---src-pages-projects-big-honkin-ideas-js" */),
  "component---src-pages-projects-dpl-financial-partners-js": () => import("./../../../src/pages/projects/dpl-financial-partners.js" /* webpackChunkName: "component---src-pages-projects-dpl-financial-partners-js" */),
  "component---src-pages-projects-emda-js": () => import("./../../../src/pages/projects/emda.js" /* webpackChunkName: "component---src-pages-projects-emda-js" */),
  "component---src-pages-projects-pasadena-showcase-house-js": () => import("./../../../src/pages/projects/pasadena-showcase-house.js" /* webpackChunkName: "component---src-pages-projects-pasadena-showcase-house-js" */),
  "component---src-pages-projects-service-magic-usa-js": () => import("./../../../src/pages/projects/service-magic-usa.js" /* webpackChunkName: "component---src-pages-projects-service-magic-usa-js" */)
}

